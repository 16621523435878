import {
  EventsApi,
  EventsApiEventsListRequest,
} from "@sakiku/vstorage-api-client";

import { makeConfiguration } from "./configuration";

const makeClient = (accessToken: string) =>
  new EventsApi(makeConfiguration(accessToken));

export const getNews = async (
  accessToken: string,
  requestParameters?: EventsApiEventsListRequest
) => {
  const client = makeClient(accessToken);
  const { data } = await client.eventsList(requestParameters);
  return data;
};
