import { withAuthenticationRequired } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";

import useNewsList from "@/hooks/news";
import { useProjectList } from "@/hooks/projects";

import BaseLayout from "@/components/BaseLayout";
import HasScope from "@/components/HasScope";
import NewsList from "@/components/NewsList";
import ProjectList from "@/components/ProjectList";

const Index = () => {
  const { status, data: projects, error, isFetching } = useProjectList();
  const { data: news } = useNewsList();
  console.log("news", news);
  console.log(projects);
  console.log(status, error, isFetching);
  return (
    <BaseLayout title="Home">
      <Container maxWidth="lg">
        <HasScope
          scope={["read:projects", "read_all:projects", "read:news"]}
          cond="some"
        >
          <Box sx={{ my: 4 }}>
            <Grid container spacing={3}>
              <HasScope
                scope={["read:projects", "read_all:projects"]}
                cond="some"
              >
                <Grid item xs={6}>
                  {projects?.results !== undefined && (
                    <ProjectList projects={projects.results} />
                  )}
                </Grid>
              </HasScope>
              <HasScope scope={"read:news"}>
                <Grid item xs={6}>
                  {news?.results !== undefined && (
                    <NewsList news={news.results} />
                  )}
                </Grid>
              </HasScope>
            </Grid>
          </Box>
        </HasScope>
      </Container>
    </BaseLayout>
  );
};

export default withAuthenticationRequired(Index);
