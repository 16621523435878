import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { AddIcon, CircleIcon } from "@sakiku/react-component";
import { Project } from "@sakiku/vstorage-api-client";

import HasScope from "@/components/HasScope";
import { NextLinkComposed } from "@/components/Link";

export default function ProjectList(props: { projects: Project[] }) {
  const { projects } = props;
  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" sx={{ ml: 2 }}>
            プロジェクト
          </Typography>
        </Grid>
        <HasScope scope={"create:projects"}>
          <Grid item>
            <Button
              href="/project_add"
              sx={{ bgcolor: "green", mr: 2, "&:hover": { bgcolor: "green" } }}
            >
              <AddIcon />
            </Button>
          </Grid>
        </HasScope>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <List>
          {projects.map((v) => (
            <ListItem
              disablePadding
              key={v.slug}
              sx={{ bgcolor: "background.paper", my: 1 }}
            >
              <ListItemButton
                component={NextLinkComposed}
                to={{ pathname: `/projects/${v.slug}` }}
              >
                <CircleIcon sx={{ mr: 1 }} />
                <Box>
                  <ListItemText primary={v.name} />
                  <ListItemText secondary={v.slug} />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
          <HasScope scope={"create:projects"}>
            <ListItem
              disablePadding
              sx={{ bgcolor: "background.paper", my: 1 }}
            >
              <ListItemButton
                component={NextLinkComposed}
                to={{ pathname: `/project_add` }}
              >
                <Button
                  sx={{
                    bgcolor: "green",
                    mr: 2,
                    "&:hover": { bgcolor: "green" },
                  }}
                >
                  <AddIcon />
                </Button>
                <ListItemText primary={"プロジェクトを追加する"} />
              </ListItemButton>
            </ListItem>
          </HasScope>
        </List>
      </Box>
    </div>
  );
}
