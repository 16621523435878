import { EventsApiEventsListRequest } from "@sakiku/vstorage-api-client";
import { useQuery } from "react-query";

import { getNews } from "@/api/news";

import { useAccessToken } from "./auth0";

export default function useNewsList(
  requestParameters?: EventsApiEventsListRequest,
  enabled: boolean = true
) {
  const accessToken = useAccessToken({
    audience: "https://v-storage-django-api/",
  });
  const queryKey = ["newsList", accessToken, requestParameters];
  return useQuery(
    queryKey,
    () => {
      if (!accessToken) return undefined;
      return getNews(accessToken, requestParameters);
    },
    { enabled: enabled }
  );
}
