/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { PersonIcon } from "@sakiku/react-component";
import { Event, EventTypeEnum } from "@sakiku/vstorage-api-client";
import moment from "moment";
import "moment/locale/ja";

import { NextLinkComposed } from "@/components/Link";

const EventListText = styled.div`
  padding: 5px;
  border-radius: 4px;
  width: 100%;
`;

type EventTypeParam = {
  color: string;
  target: keyof Event;
  action: string;
  getLink?: (event: Event) => string;
};

const eventTypeText: {
  [key in EventTypeEnum]: EventTypeParam;
} = {
  [EventTypeEnum.ProjectAdd]: {
    color: "#f5c242",
    target: "project_name",
    action: "を追加",
    getLink: (event) => `/projects/${event.project}`,
  },
  [EventTypeEnum.ProjectDelete]: {
    color: "#F06A46",
    target: "project_name",
    action: "を削除",
  },
  [EventTypeEnum.ProjectRename]: {
    color: "#A950E0",
    target: "project_name",
    action: "の名前を変更",
    getLink: (event) => `/projects/${event.project}`,
  },
  [EventTypeEnum.TableAdd]: {
    color: "#4daa5a",
    target: "table_name",
    action: "を追加",
    getLink: (event) => `/projects/${event.project}/tables/${event.table}`,
  },
  [EventTypeEnum.TableDelete]: {
    color: "#F06A46",
    target: "table_name",
    action: "を削除",
    getLink: (event) => `/projects/${event.project}`,
  },
  [EventTypeEnum.TableRename]: {
    color: "#A950E0",
    target: "table_name",
    action: "の名前を変更",
    getLink: (event) => `/projects/${event.project}/tables/${event.table}`,
  },
  [EventTypeEnum.TableChange]: {
    color: "#4cadea",
    target: "table_name",
    action: "を変更",
    getLink: (event) => `/projects/${event.project}/tables/${event.table}`,
  },
  [EventTypeEnum.UserAdd]: {
    color: "#4daa5a",
    target: "action_user_name",
    action: "を追加",
  },
};

function EventListItem(props: { event: Event }) {
  const { event } = props;
  const item = eventTypeText[event.type];

  return (
    <ListItem disablePadding sx={{ bgcolor: "background.paper", my: 1 }}>
      <ListItemButton
        component={NextLinkComposed}
        to={{
          pathname: item.getLink ? item.getLink(event) : `/news/${event.id}`,
        }}
      >
        <EventListText>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ width: "100%" }}>
              <PersonIcon />
              {event.action_user_name}さんが
              <span
                css={css`
                  background-color: ${item.color};
                  font-weight: bold;
                  color: white;
                  padding: 0.2rem 0.5rem;
                  border-radius: 1rem;
                `}
              >
                {event[item.target]}
              </span>
              {item.action}しました
            </Typography>
            <Typography sx={{ flexShrink: 0 }}>
              <Tooltip
                title={moment(event.created_at).format("l LTS")}
                placement="top"
              >
                <span
                  css={css`
                    color: #808080;
                  `}
                >
                  {moment(event.created_at).fromNow()}
                </span>
              </Tooltip>
            </Typography>
          </Box>
        </EventListText>
      </ListItemButton>
    </ListItem>
  );
}

export default function NewsList(props: { news: Event[] }) {
  const { news } = props;
  console.log(news);
  return (
    <div>
      <Typography variant="h6" sx={{ ml: 2 }}>
        最近の更新
      </Typography>
      <Box sx={{ width: "100%" }}>
        <List>
          {news.map((v) => (
            <EventListItem event={v} key={v.id} />
          ))}
        </List>
      </Box>
    </div>
  );
}
