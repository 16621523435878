import { ReactNode, useMemo } from "react";

import { useScopes } from "@/hooks/auth0";

export default function HasScope(props: {
  children: ReactNode;
  loading?: ReactNode;
  error?: ReactNode;
  scope: string | string[];
  cond?: "some" | "every"; // Default: "every"
}) {
  const { children, loading, error, scope, cond } = props;

  const scopeStr = useMemo(() => {
    if (typeof scope === "string") return scope;
    return scope.join(" ");
  }, [scope]);
  const { scopes, isLoading } = useScopes({
    audience: "https://v-storage-django-api/",
    scope: scopeStr,
  });

  const isShown = useMemo(() => {
    if (typeof scope === "string") return scopes?.includes(scope);
    if (cond === "some") return scope.some((value) => scopes?.includes(value));
    return scope.every((value) => scopes?.includes(value));
  }, [cond, scope, scopes]);

  return <>{isLoading ? loading : <>{isShown ? children : error}</>}</>;
}
